import React from 'react';
import './SectionStab.css';

function SectionStab() {
  return (
    <p className="SectionStab">Раздел находится в разработке</p>
  );
}

export default SectionStab;
