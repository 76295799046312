import React from 'react';
import './About.css';

function About() {
  return (
    <div className="About">
      <h2>Фильм</h2>
      <iframe width="800"
              height="450"
              src="https://www.youtube.com/embed/RKnDLiyvIWM"
              title="Фильм об организации"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="">
      </iframe>
      <a className="book" href="https://storage.yandexcloud.net/pisatel22/docs/book-about-organization.pdf"
         target="_blank">
        <h2>Книга</h2>
        <img src="/book-about-organization.jpg" alt="Алтайское краевое отделение Союза писателей СССР (история создания и развития, 1946–1991 гг.)" />
      </a>
      <h2>Документы</h2>
      <p>
        <a href="https://storage.yandexcloud.net/pisatel22/docs/ustav.pdf" target="_blank" rel="noopener noreferrer">
          Устав Общественной Алтайской краевой писательской организации "Союз писателей России"
        </a>
      </p>
      <p>
        <a href="https://storage.yandexcloud.net/pisatel22/docs/svidetelstvo.pdf" target="_blank" rel="noopener noreferrer">
          Свидетельство о регистрации
        </a>
      </p>
      <p>
        <a href="https://storage.yandexcloud.net/pisatel22/docs/pismo-prvlenia.pdf" target="_blank" rel="noopener noreferrer">
          Письмо Правления Союза писателей России
        </a>
      </p>
    </div>
  );
}

export default About;
